<template>
  <a-spin :spinning="loading" size="large">
      <div class="login_with_infos">
        <a-form-item label="Kullanıcı Adı" v-bind="validateInfos.username">
          <a-input name="username" v-model:value="modelRef.username"/>
        </a-form-item>

        <a-form-item label="Kullanıcı Emaili" v-bind="validateInfos.email">
          <a-input name="email" v-model:value="modelRef.email" :disabled="true"/>
        </a-form-item>

        <a-form-item label="Şifre" v-bind="validateInfos.password">
          <a-input name="password" type="password" v-model:value="modelRef.password"/>
        </a-form-item>

        <a-form-item label="Kullanıcı Tipini belirtiniz" v-bind="validateInfos.user_type">
          <a-select
              v-model:value="modelRef.user_type"
          >
            <a-select-option value="USER">USER</a-select-option>
            <a-select-option value="STUDENT">STUDENT</a-select-option>
            <a-select-option value="EDUCATOR">EDUCATOR</a-select-option>
            <a-select-option value="NEW_USER">NEW_USER</a-select-option>
          </a-select>
        </a-form-item>

      </div>
      <a-divider orientation="left">
        <a-form-item :wrapper-col="{ span: 14, offset: 1 }">
          <a-button type="primary" @click="onSubmit">
            {{ $route.params.userId ? 'Değiştir' : 'Ekle' }}
          </a-button>
          <a-button style="margin-left: 10px;" @click="resetFields">
            Sıfırla
          </a-button>
        </a-form-item>
      </a-divider>


  </a-spin>
</template>

<script>
import {getCurrentInstance, inject, onMounted, onUpdated, reactive, ref} from 'vue'
import {notification} from 'ant-design-vue'
import {useRoute, useRouter} from 'vue-router'
import {Form} from 'ant-design-vue'

export default {
  name: 'AddOrEditUserUser',
  props: ['userResponse'],
  emits: ['updateUser'],

  setup(props, {emit}) {
    const useForm = Form.useForm
    const route = useRoute()
    const loading = ref(false)

    const axios = inject('axios')

    const modelRef = reactive({
      email: props.userResponse?.email ?? '',
      username: props.userResponse?.username ?? '',
      password: props.userResponse?.password ?? '',
      user_type: props.userResponse?.user_type ?? 'STUDENT'
    })


    const rulesRef = reactive({
      email: [
        {
          required: true,
          message: 'Lütfen giriş için email giriniz'
        }
      ],
      username: [
        {
          min: 3,
          required: true,
          message: 'Lütfen kullanıcı ismini giriniz'
        }
      ],
      password: [
        {
          required: false,
          message: 'Lütfen giriş için parola giriniz'
        }
      ],
      user_type: [
        {
          required: true,
          message: 'Lütfen Kullanıcı tipi belirtiniz'
        }
      ]
    })
    const {resetFields, validate, validateInfos} = useForm(modelRef, rulesRef)

    const onSubmit = e => {
      e.preventDefault()
      loading.value = true

      validate()
          .then(form => {

            axios.post('/users/' + route.params.userId + '/edit', form)
                .then((response) => {

                  notification.success({
                    message: response.data.message
                  })
                  loading.value = false

                  emit('updateUser')

                })
          })
          .catch(err => {
            console.log('error', err)
            loading.value = false
          })
          // .finally(() => loading.value = false)
    }

    return {
      resetFields,
      validateInfos,
      modelRef,
      onSubmit,
      loading
    }

  }
}
</script>

<style scoped>

</style>
