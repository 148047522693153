import {reactive, inject, ref} from 'vue'

export default function useUserRepositories() {

  const axios = inject('axios')

  const empty = {
    data: [],
    visible: false,
    selected: -1,
    disabled: function () {
      return this.current === -1
    }
  }

  let availableCourses = reactive({...empty})
  let availableEducators = reactive({...empty})
  let userCourses = ref([])


  const loadAvaliableEducators = () => {

    Object.assign(availableEducators, {...empty})

    axios.get('users/available-educators').then(response => {

      availableEducators.data = response.data.educators.map(item => {

        return {
          label: `${item.credential.name} ${item.credential.surname.toUpperCase()}`,
          value: item.id
        }

      })
      availableEducators.selected = availableEducators.data[0].value


    })

  }

  const loadAvaliableCourses = async (userId) => {

    Object.assign(availableCourses, {...empty})

    return axios.get('users/' + userId + '/available-courses').then(response => {

      availableCourses.data = response.data.courses.map(item => {

        return {
          label: `${item.category.name}: ${item.name}`,
          value: item.id
        }

      })
      availableCourses.selected = availableCourses.data[0].value


    })

  }

  const retrieveUserCourse = async (userCourseId)=> axios.post('user-courses/' + userCourseId + '/retrieve')

  const loadUserCourses = async (userId) => {

    let url = '/users'

    if (userId) {
      url = url + '/' + userId + '/user-courses'
    }


    return axios.get(url).then(_response => {

      userCourses.value = _response.data

    })
  }

  const assignUserCourses = (userId) => {

    return axios.post('users/assign-user-courses', {
      course_id: availableCourses.selected,
      educator_id: availableEducators.selected,
      user_id:parseInt(userId,10)
    })

  }

  const editUserCourse = (userId,userCourseId,level,expiresAt) => {
    return axios.post('users/update-user-course', {
      user_id:parseInt(userId),
      user_course_id: userCourseId,
      course_id: availableCourses.selected,
      educator_id: availableEducators.selected,
      level:level,
      expires_at:expiresAt
    })
  }

  const deleteUserCourse = (userCourseId) => {

    return axios.delete('users/user-courses/' + userCourseId)

  }


  return {
    userCourses,
    availableCourses,
    availableEducators,
    loadUserCourses,
    editUserCourse,
    loadAvaliableEducators,
    loadAvaliableCourses,
    deleteUserCourse,
    assignUserCourses,
    retrieveUserCourse
  }
}
