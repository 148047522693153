<template>
  <a-button class="editable-add-btn" @click="showModal" style="margin-bottom: 8px">Ekle</a-button>
  <a-modal
      title="Yeni Kurs Ekle"
      v-model:visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      width="800px"
  >


    <a-row type="flex">

      <a-col :flex="2">
        Kurs Seçin
      </a-col>

      <a-col>
        <a-select
            ref="course_select"
            :disabled="availableCourses.disabled()"
            :value="availableCourses.selected"
            @change="availableCourses.selected = $event"
            style="width: 600px"
            :options="availableCourses.data"
        >
        </a-select>
      </a-col>

    </a-row>
    <a-row type="flex">

      <a-col :flex="2">
        Eğitmen Seçin
      </a-col>

      <a-col>
        <a-select
            ref="educator_select"
            :disabled="availableEducators.disabled()"

            :value="availableEducators.selected"
            @change="availableEducators.selected = $event"
            style="width: 600px"
            :options="availableEducators.data"
        >
        </a-select>
      </a-col>

    </a-row>


  </a-modal>
</template>

<script>
import {inject, reactive, ref} from 'vue'
import {notification} from 'ant-design-vue'
import {useRoute} from 'vue-router'
import useUserCourse from '../../../composables/useUserCourse.js'

export default {
  name: 'NewCourse',
  emits: ['loadUserCourses'],
  setup(_, {emit}) {
    const route = useRoute()

    const visible = ref(false)
    const confirmLoading = ref(false)

    const {
      loadAvaliableCourses,
      loadAvaliableEducators,
      availableCourses,
      availableEducators,
      assignUserCourses
    } = useUserCourse()


    const showModal = () => {

      visible.value = true
      loadAvaliableCourses(route.params.userId)
      loadAvaliableEducators()

    }

    const handleOk = () => {

      console.log(`selected course ${availableCourses.selected}`)
      console.log(`selected educator ${availableEducators.selected}`)
      confirmLoading.value = true

      if (availableCourses.selected === -1 || availableEducators.selected === -1) {
        notification.error({message: 'Lütfen Kurs ve Eğitmen seçiniz'})
        return
      }

      return assignUserCourses(route.params.userId).then(response => {
        visible.value = false
        confirmLoading.value = false
        emit('loadUserCourses')
      }).catch(err => {
        confirmLoading.value = false
        throw err
      })


    }


    return {
      visible,
      confirmLoading,
      showModal,
      availableCourses,
      availableEducators,
      handleOk
    }

  }


}
</script>

<style scoped>

</style>
