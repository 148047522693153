<template>
  <a-button type="primary" @click="showModal">Düzenle</a-button>
  <a-modal
      title="Kursları Düzenle"
      v-model:visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      width="800px"
  >


    <a-row type="flex">

      <a-col :flex="2">
        Kurs Seçin
      </a-col>

      <a-col>
        <a-select
            ref="course_select"
            :disabled="availableCourses.disabled()"
            :value="availableCourses.selected"
            @change="availableCourses.selected = $event"
            style="width: 600px"
            :options="availableCourses.data"
        >
        </a-select>
      </a-col>

    </a-row>
    <a-row type="flex">

      <a-col :flex="2">
        Eğitmen Seçin
      </a-col>

      <a-col>
        <a-select
            ref="educator_select"
            :disabled="availableEducators.disabled()"

            :value="availableEducators.selected"
            @change="availableEducators.selected = $event"
            style="width: 600px"
            :options="availableEducators.data"
        >
        </a-select>
      </a-col>

    </a-row>
    <a-row type="flex">

      <a-col :flex="2">
        Level
      </a-col>

      <a-col>
        <a-select
            ref="educator_select"
            :value="selectedLevel"
            @change="selectedLevel = $event"
            style="width: 600px"
            :options="levels"
        >
        </a-select>
      </a-col>

    </a-row>

    <a-row type="flex">

      <a-col :flex="2">
        Son bitiş tarihi
      </a-col>

      <a-col style="text-align: left">
        <a-date-picker v-model:value="expiresAt" format="DD.MM.YYYY" />
      </a-col>

    </a-row>


  </a-modal>
</template>

<script>
import {ref} from 'vue'
import {notification} from 'ant-design-vue'
import {useRoute} from 'vue-router'
import useUserCourse from '../../../composables/useUserCourse'
import moment,{Moment} from 'moment'

export default {
  name: 'EditCourse',
  emits: ['loadUserCourses'],
  props: ['selectedUserCourse'],
  setup(props, {emit}) {
    const route = useRoute()

    const visible = ref(false)
    const confirmLoading = ref(false)
    const date = moment(props.selectedUserCourse.expires_at,'YYYY-MM-DDTHH:i:mm.000Z')

    const expiresAt = ref(date)

    const {
      loadAvaliableCourses,
      loadAvaliableEducators,
      availableCourses,
      availableEducators,
      editUserCourse,
      retrieveUserCourse
    } = useUserCourse()

    const levels = new Array(10).fill(1).map((_,index) => ({value:index+1}))
    let selectedLevel = ref(levels.find(level => level.value === props.selectedUserCourse.level)?.value || 1)


    const showModal = async () => {

      visible.value = true
      return Promise.all([
        loadAvaliableCourses(route.params.userId),
        loadAvaliableEducators()
      ]).then(() => {
        availableCourses.data.push({
          label:props.selectedUserCourse.course.name,
          value:props.selectedUserCourse.course.id
        }) //Seçileni ekle

        const foundAvailableCourse = availableCourses.data.find(availableCourse => availableCourse.value.toString() ===  props.selectedUserCourse.course.id.toString())
        availableCourses.selected = foundAvailableCourse.value
        availableEducators.selected = availableEducators.data.find(educatorOption => educatorOption.value.toString() === props.selectedUserCourse.educator.id.toString()).value
      })


    }


    const handleOk = () => {

      confirmLoading.value = true

      if (availableCourses.selected === -1 || availableEducators.selected === -1) {
        notification.error({message: 'Lütfen Kurs ve Eğitmen seçiniz'})
        return
      }

      return editUserCourse(
          parseInt(route.params.userId),
          props.selectedUserCourse.id,
          parseInt(selectedLevel.value),
          expiresAt.value.unix()
      ).then(response => {
        visible.value = false
        confirmLoading.value = false
        emit('loadUserCourses')
      }).catch(err => {
        confirmLoading.value = false
        throw err
      })

    }


    return {
      expiresAt,
      // userCourse,
      selectedLevel,
      levels,
      visible,
      confirmLoading,
      showModal,
      availableCourses,
      availableEducators,
      handleOk
    }

  }


}
</script>

<style scoped>

</style>
