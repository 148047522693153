<template>
  <a-spin :spinning="loading" tip="Loading..." size="large">


    <NewCourse v-if="!loading && !isEducator" @loadUserCourses="loadUserCourses($route.params.userId)"/>
    <a-table bordered :data-source="userCourses" v-if="!loading" :columns="columns" row-key="id" :customRow="customRow">
      <template #educatorOrUser="{ record }">

        {{
          record.isEducator ?
              record.user.credential.name + ' ' + record.user.credential.surname :
              record.educator.credential.name + ' ' + record.educator.credential.surname
        }}

      </template>
      <template #operation="{ record }">

        <div v-if="!record.deleted_at">
          <a-space>
            <EditCourse v-if="!loading && !isEducator" :selectedUserCourse="record" @loadUserCourses="loadUserCourses($route.params.userId)"/>

            <!--          <a href="#" @click="retrieveAndRefresh(this,record.id)">asdasd</a>-->

            <a-popconfirm v-if="!isEducator" title="Sure to delete?" @confirm="deleteAndRefresh(record.id)">
              <a-button danger>Sil</a-button>
              <!--            <a href="#">Sil</a>-->
            </a-popconfirm>
          </a-space>

        </div>
        <div v-else>
          Silinmiş
        </div>

      </template>

    </a-table>
  </a-spin>
</template>

<script>
import {onBeforeMount, ref} from 'vue'
import {useRoute} from 'vue-router'
import NewCourse from './NewCourse'
import EditCourse from './EditCourse'
import useUserRepositories from '../../../composables/useUserCourse'
import moment from 'moment'

export default {
  name: 'UserCourse',
  components: {EditCourse, NewCourse},
  props: ['isEducator'],
  setup(props) {
    const route = useRoute()


    const visible = ref(false)
    const confirmLoading = ref(false)

    const loading = ref(true)
    const modalLoading = ref(true)

    const {
      userCourses,
      loadUserCourses,
      deleteUserCourse,
    } = useUserRepositories()


    loadUserCourses(route.params.userId)
        .then(() => {
          userCourses.value.map(userCourse => {
            userCourse.isEducator = userCourse.user_id.toString() !== route.params.userId.toString()
          })
          columns.value[2].title = props.isEducator ? 'Kursiyer' : 'Eğitmen'

          loading.value = false

        })

    const deleteAndRefresh = async (userCourseId) => {
      await deleteUserCourse(userCourseId)
      await loadUserCourses(route.params.userId)
    }

    const tranformDate = function ({text, record, index}) {

      const date  = moment(text,'YYYY-MM-DD')

      return date.format('DD.MM.YYYY')

    }
    const columns = ref([
      {
        title: 'Kategori',
        dataIndex: 'course.category.name'
      },
      {
        title: 'Kurs İsmi',
        dataIndex: 'course.name',
        slots: {
          customRender: 'default'
        },

      },

      {
        title: 'Eğitmen',
        slots: {
          customRender: 'educatorOrUser'
        }
      },
      {
        title: 'Başlangıç Zamanı',
        dataIndex: 'created_at',
        customRender:tranformDate
      },
      {
        title: 'Bitiş Zamanı',
        dataIndex: 'expires_at',
        customRender:tranformDate
      },
      {
        title: 'Level',
        dataIndex: 'level'
      },
      {
        title: 'İşlem',
        dataIndex: 'operation',
        slots: {
          customRender: 'operation'
        }
      }
    ])

    const customRow = (record, index) => {

      if (record.deleted_at) {
        return {disabled: true, background: '#0000'}
      } else {
        return {background: '#ffffff'}
      }
    }


    onBeforeMount(() => {
      loadUserCourses(route.params.userId)
    })


    return {
      customRow,
      columns,
      userCourses,
      loading,
      modalLoading,
      visible,
      confirmLoading,
      loadUserCourses,
      deleteAndRefresh,
    }

  }
}
</script>

<style scoped></style>
