<template>
  <div class="login_with_infos">
    <a-form-item label="Adı" v-bind="validateInfos.name">
      <a-input name="username" v-model:value="modelRef.name"/>
    </a-form-item>

    <a-form-item label="Soyadı" v-bind="validateInfos.surname">
      <a-input name="email" v-model:value="modelRef.surname"/>
    </a-form-item>

    <a-form-item label="Telefon Numarası 905xxxxxxxxx" v-bind="validateInfos.phone">
      <a-input name="phone" v-model:value="modelRef.phone"/>
    </a-form-item>

    <a-form-item label="Cinsiyet" v-bind="validateInfos.sex">
      <a-select
          v-model:value="modelRef.sex"
      >
        <a-select-option value="MALE">MALE</a-select-option>
        <a-select-option value="FEMALE">FEMALE</a-select-option>
      </a-select>
    </a-form-item>

  </div>
  <a-divider orientation="left">
    <a-form-item :wrapper-col="{ span: 14, offset: 1 }">
      <a-button type="primary" @click="onSubmit">
        {{ $route.params.userId ? 'Değiştir' : 'Ekle' }}
      </a-button>
      <a-button style="margin-left: 10px;" @click="resetFields">
        Sıfırla
      </a-button>
    </a-form-item>
  </a-divider>
</template>

<script>
import {getCurrentInstance, inject, onMounted, reactive} from 'vue'
import {notification} from 'ant-design-vue'
import {useRoute, useRouter} from 'vue-router'
import {Form} from 'ant-design-vue'

export default {
  name: 'AddOrEditUserCredential',
  props: ['userCredential'],
  setup(props) {
    const useForm = Form.useForm
    const route = useRoute()
    const router = useRouter()

    const axios = inject('axios')

    const modelRef = reactive({
      name: props.userCredential?.name ?? '',
      surname: props.userCredential?.surname ?? '',
      phone: props.userCredential?.phone ?? '',
      sex: props.userCredential?.sex ?? 'MALE'
    })


    const rulesRef = reactive({
      name: [
        {
          required: true,
          message: 'Lütfen adı giriniz.'
        }
      ],
      surname: [
        {
          min: 3,
          required: true,
          message: 'Lütfen soyad giriniz.'
        }
      ],
      phone: [
        {
          required: true,
          message: 'Lütfen telefon giriniz. 905xxxxxxxxx'
        }
      ],
      sex: [
        {
          required: true,
          message: 'Lütfen cinsiyet belirtiniz'
        }
      ]
    })
    const {resetFields, validate, validateInfos} = useForm(modelRef, rulesRef)

    const onSubmit = e => {
      e.preventDefault()

      validate()
          .then(() => {
            axios.post('/users/' + route.params.userId + '/credential/edit', modelRef)
                .then((response) => {

                  notification.success({
                    message: response.data.message
                  })
                  router.back()

                })
          })
          .catch(err => {
            console.log('error', err)
          })
    }

    return {
      resetFields,
      validateInfos,
      modelRef,
      onSubmit
    }

  }
}
</script>

<style scoped>

</style>
