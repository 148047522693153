<template>

  <a-card class="general_card add_form" :bordered="false" :loading="loading" title="Kullanıcı Düzenleme">
    <template #extra>
      <EditFilled/>
    </template>


    <a-form :label-col="labelCol" :wrapper-col="wrapperCol">

      <a-collapse v-model:activeKey="activeKey">
        <a-collapse-panel key="1" header="Kullanıcı ayarları">
          <AddOrEditUserUser :userResponse="response.user" @updateUser="fetchUserInfos"/>
        </a-collapse-panel>

        <a-collapse-panel key="2" header="Kişi Bilgileri">
          <AddOrEditUserCredential :userCredential="response.user.credential"/>
        </a-collapse-panel>
        <a-collapse-panel key="3" header="Kurslar" >
          <UserCourse :isEducator="['ADMIN','EDUCATOR','MASTER'].includes(response.user.user_type)"/>
        </a-collapse-panel>
      </a-collapse>


    </a-form>
  </a-card>

</template>


<script>

import {EditFilled} from '@ant-design/icons-vue'

import {useRoute} from 'vue-router'
import {ref, onBeforeMount, inject} from 'vue'

import UserCourse from './AddOrEditUser/UserCourse'
import AddOrEditUserUser from './AddOrEditUser/User'
import AddOrEditUserCredential from './AddOrEditUser/Credential'

export default {

  name: 'AddOrEditUser',
  components: {
    UserCourse,
    AddOrEditUserCredential,
    AddOrEditUserUser,
    EditFilled
  },
  setup() {
    const route = useRoute()
    const axios = inject('axios')

    let response = ref({})
    const loading = ref(true)


    onBeforeMount(() => {
      fetchUserInfos()
    })

    const fetchUserInfos = async () => {
      let url = '/users'

      if (route.params.userId) {
        url = url + '/' + route.params.userId + '/edit'
      }


      return axios.get(url).then(_response => {

        response.value = _response.data
        loading.value = false

      })

    }

    const activeKey = ref(['1'])


    return {
      labelCol: {span: 24},
      wrapperCol: {span: 23},
      loading,
      activeKey,
      response,
      fetchUserInfos
    }
  }
}
</script>
